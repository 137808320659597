@charset "UTF-8";
html {
  box-sizing: border-box;
}

template,
[hidden] {
  display: none;
}

*,
:before,
:after {
  box-sizing: inherit;
}

address {
  font-style: inherit;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p,
figure {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a, area, button, input, label, select, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hreflang] > abbr[title] {
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  background-color: #CCCCCC;
}

@font-face {
  font-display: swap;
  font-family: "Mona Sans";
  src: url("../fonts/Mona-Sans.woff2") format("woff2 supports variations"), url("../fonts/Mona-Sans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
}
/* outfit-300 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/outfit-v6-latin-300.woff2") format("woff2"), url("../fonts/outfit-v6-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-200 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/outfit-v6-latin-200.woff2") format("woff2"), url("../fonts/outfit-v6-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/outfit-v6-latin-regular.woff2") format("woff2"), url("../fonts/outfit-v6-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/outfit-v6-latin-regular.woff2") format("woff2"), url("../fonts/outfit-v6-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/outfit-v6-latin-500.woff2") format("woff2"), url("../fonts/outfit-v6-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/outfit-v6-latin-600.woff2") format("woff2"), url("../fonts/outfit-v6-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/outfit-v6-latin-700.woff2") format("woff2"), url("../fonts/outfit-v6-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-800 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/outfit-v6-latin-800.woff2") format("woff2"), url("../fonts/outfit-v6-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* outfit-900 - latin */
@font-face {
  font-display: swap;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/outfit-v6-latin-900.woff2") format("woff2"), url("../fonts/outfit-v6-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.capitalize::first-letter {
  text-transform: capitalize;
}

.pointer:hover {
  cursor: pointer;
}

.link-primary {
  transition: color 0.35s;
}
.link-primary:hover {
  color: #19827D !important;
  cursor: pointer;
}

.ovh {
  overflow: hidden;
}

.spacer {
  width: 100%;
  height: 150px;
}

.blend-mode {
  background-color: #14191F;
}
.blend-mode img {
  mix-blend-mode: lighten;
}

.border-10 {
  border-radius: 10px;
  overflow: hidden;
}

.mt3 {
  margin-top: 3rem !important;
}

hr {
  max-width: 70%;
  margin: 5rem auto;
  background-color: #19827D;
}

[unresolved] {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
  list-style-type: none;
}

html {
  height: 100%;
}

body {
  position: relative;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.618;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  background: #0a0d10;
  color: #f8f7f7;
  overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: #EFEFEF;
  transition: color 0.35s;
}

.smooth-scroll {
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
}

.wrapper {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  background: #14191F;
}
.page-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 1200px) {
  .page-bg .grad-top-left img {
    max-width: 500px;
  }
}
@media (min-width: 1920px) {
  .page-bg .grad-top-left img {
    max-width: 911px;
  }
}

p {
  font-size: calc(0.25vw + 15.2px);
}

p:not(:last-child) {
  margin-bottom: 1rem;
}

main {
  position: relative;
}

section {
  visibility: hidden;
}
section:not(:first-of-type) {
  margin-top: calc(9.375vw + 170px);
  margin-bottom: calc(9.375vw + 170px);
}
section:first-of-type {
  margin-top: calc(6.25vw + 30px);
}

.section-padding {
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .section-padding {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .section-padding {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .section-padding {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .section-padding {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .section-padding {
    padding: 0 8rem;
  }
}
.section-full {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem 0;
}
.section-full .section-full-content {
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .section-full .section-full-content {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .section-full .section-full-content {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .section-full .section-full-content {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .section-full .section-full-content {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .section-full .section-full-content {
    padding: 0 8rem;
  }
}
.section-grey {
  background-color: #28323D;
}

.section-gradient {
  background: url("../images/static/gradient-full.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: lighten;
}

.category-headline {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: solid 2px #19827D;
}

.section-headline {
  display: inline-block;
  font-size: calc(0.75vw + 15.6px);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2rem;
  border-bottom: solid 2px #19827D;
  padding-bottom: 0.25rem;
}

.section-intro {
  font-size: calc(0.125vw + 15.6px);
  margin-bottom: 4rem;
}

.block-headline {
  display: inline-block;
  font-size: calc(0.4375vw + 16.6px);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
}

.block {
  font-size: calc(0.125vw + 15.6px);
}
.block .block-headline-lower {
  display: inline-block;
  font-size: calc(0.4375vw + 16.6px);
  font-weight: 500;
  margin-bottom: 1rem;
}

.block-single-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.block-double {
  display: flex;
  flex-wrap: wrap;
}
.block-double .left {
  flex: 1 1 200px;
}
.block-double .right {
  flex: 1 1 1000px;
}
.block-double .right .block-disclaimer {
  margin-top: calc(0.625vw + 38px);
}

.block-double-equal {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  column-gap: 12rem;
  row-gap: 4rem;
}

.block-double-equal-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  column-gap: 4rem;
  row-gap: 4rem;
}

.block-quad {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  gap: 4rem;
}

.section-agentur-start .block-double {
  display: flex;
  flex-wrap: wrap;
}
.section-agentur-start .block-double .left {
  flex: 1 1 200px;
}
.section-agentur-start .block-double .right {
  flex: 1 1 600px;
}
.section-agentur-start .block-double .right .block-disclaimer {
  margin-top: calc(0.625vw + 38px);
}
.section-agentur-start .cta {
  margin-top: 2rem;
}
.section-agentur-start .image-earth {
  max-width: 1100px;
}

.lost-in-space .second {
  margin-top: 1rem;
}
.lost-in-space .second ul {
  font-weight: 200;
  padding-left: 1.5rem;
  list-style: square;
}
.lost-in-space .second ul li {
  list-style-type: square;
  margin-bottom: 0.5rem;
}
.lost-in-space .second ul li a {
  text-decoration: underline;
}

.game {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.game .game-inner {
  text-align: center;
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  /* Style für Spieler X und O */
  /* Farben für Gewinner */
  /* Nachrichten-Box */
  /* Neustart-Button */
}
.game .game-inner #board {
  border-collapse: collapse;
}
.game .game-inner #board td {
  width: 100px;
  height: 100px;
  border: 1px solid #3F4E5E;
  text-align: center;
  font-size: 48px;
  cursor: pointer;
}
.game .game-inner #board td.X::before {
  content: "X";
}
.game .game-inner #board td.O::before {
  content: "O";
}
.game .game-inner #board td.winner {
  background-color: #19827D;
}
.game .game-inner #message {
  text-align: center;
  height: 90px;
  line-height: 90px;
}
.game .game-inner button:hover {
  background-color: #3e8e41;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0a0d10;
  z-index: 1000;
  transform-origin: bottom;
}
.loader .loader-logo {
  width: 120px;
  fill: #EFEFEF;
}
@media (min-width: 768px) {
  .loader .loader-logo {
    width: 200px;
  }
}
.loader .loader-text {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 6px;
  color: #323f4e;
}
.loader .loader-bar {
  width: 80%;
  max-width: 200px;
  height: 3px;
  background-color: #1e262f;
  transform-origin: right;
}
.loader .loader-bar .bar-progress {
  width: 0;
  height: 3px;
  background-color: #EFEFEF;
  transform-origin: left;
}

.curtain {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  height: 100dvh;
  background-color: #14191F;
  visibility: hidden;
  z-index: 700;
}

.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 800;
}
@media (min-width: 1024px) {
  .nav-mobile {
    display: none;
  }
}
.nav-mobile .brand-mobile-logo {
  display: inline-block;
  line-height: 1;
}
.nav-mobile .brand-mobile-logo svg {
  display: block;
  width: 60px;
  fill: #EFEFEF;
}

.nav-mobile-scrolled {
  background-color: rgba(20, 25, 31, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.sidenav {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  width: 100%;
  background-color: #14191F;
  z-index: 905;
}
.sidenav .brand-sidenav-logo {
  padding: 1rem 0;
}
.sidenav .brand-sidenav-logo svg {
  display: block;
  width: calc(10vw + 28px);
  fill: #EFEFEF;
  margin-inline: auto;
}
.sidenav .sidenav-links {
  margin-top: calc(10vw + 128px);
  margin-left: calc(6vw + 12.8px);
}
.sidenav .sidenav-links li {
  visibility: hidden;
  transform: translateX(-30px);
  margin-bottom: 2rem;
}
.sidenav .sidenav-links li .sidenav-link {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: calc(1.5625vw + 35px);
  line-height: 1;
}
.sidenav .link-mobile-active {
  color: #19827D !important;
}
.sidenav .sidenav-close {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.sidenav .sidenav-gradient {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.hamburger {
  position: fixed;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  z-index: 900;
}
@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }
}
.hamburger .icon {
  fill: #EFEFEF;
}

.nav-desktop {
  display: none;
  background-color: rgba(20, 25, 31, 0.001);
  padding: 0 2rem;
  transition: background-color 0.5s;
  z-index: 800;
}
@media (min-width: 768px) {
  .nav-desktop {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .nav-desktop {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .nav-desktop {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .nav-desktop {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .nav-desktop {
    padding: 0 8rem;
  }
}
@media (min-width: 1024px) {
  .nav-desktop {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    max-width: 1920px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    z-index: 800;
  }
}
@media (min-width: 1400px) {
  .nav-desktop {
    height: 140px;
  }
}
.nav-desktop .brand-desktop {
  position: relative;
  transform-origin: left center;
}
.nav-desktop .brand-desktop a svg {
  display: block;
  width: 100px;
  fill: #EFEFEF;
  transition: fill 0.35s;
}
@media (min-width: 1200px) {
  .nav-desktop .brand-desktop a svg {
    width: 60px;
  }
}
@media (min-width: 1921px) {
  .nav-desktop .brand-desktop a svg {
    width: 80px;
  }
}
.nav-desktop .brand-desktop a:hover svg {
  fill: #19827D;
}
.nav-desktop .links-desktop {
  display: flex;
}
.nav-desktop .links-desktop .link {
  margin-right: 1rem;
}
.nav-desktop .links-desktop .link .link-dt {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  color: #EFEFEF;
  letter-spacing: 2px;
  padding-bottom: 0.25rem;
  transition: color 0.35s;
}
@media (min-width: 1200px) {
  .nav-desktop .links-desktop .link .link-dt {
    font-size: 0.64rem;
  }
}
@media (min-width: 1400px) {
  .nav-desktop .links-desktop .link .link-dt {
    font-size: 0.8125rem;
  }
}
.nav-desktop .links-desktop .link .link-dt:hover {
  color: #19827D;
}
.nav-desktop .links-desktop .link .link-active {
  position: relative;
}
.nav-desktop .links-desktop .link .link-active:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40%;
  transform: translateX(-50%);
  height: 1px;
  background-color: #19827D;
}

.nav-background {
  background-color: #14191F;
}

header {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  row-gap: 2rem;
  align-items: center;
  align-content: center;
  padding: 4rem 2rem 0 2rem;
}
@media (min-width: 768px) {
  header {
    padding: 6rem 4rem 0 4rem;
  }
}
@media (min-width: 1024px) {
  header {
    padding: 6rem 3rem 0 3rem;
  }
}
@media (min-width: 1200px) {
  header {
    padding: 10rem 5rem 0 5rem;
  }
}
@media (min-width: 1400px) {
  header {
    padding: 10rem 6rem 0 6rem;
  }
}
@media (min-width: 1920px) {
  header {
    padding: 10rem 8rem 0 8rem;
  }
}

header > h2 {
  font-weight: 300 !important;
}

.page-header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  row-gap: 4rem;
  align-items: center;
  align-content: center;
  height: 100vh;
  height: 100svh;
  width: 100%;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .page-header {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .page-header {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .page-header {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .page-header {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .page-header {
    padding: 0 8rem;
  }
}
.page-header h2 {
  font-weight: 300 !important;
}
@media (min-width: 1200px) {
  .page-header {
    max-height: 1080px !important;
  }
}
.page-header .page-header-headline {
  font-size: clamp(1.7rem, 3.5vw, 5rem);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .page-header .page-header-headline {
    font-size: 3rem;
  }
}
@media (min-width: 1024px) {
  .page-header .page-header-headline {
    font-size: 4rem;
  }
}
.page-header .page-header-text {
  font-size: calc(0.25vw + 17.2px);
  line-height: 1.25;
  font-weight: 300;
}
.page-header .content,
.page-header .image {
  flex: 1 1 500px;
}
@media (min-width: 1200px) {
  .page-header .content {
    padding-right: 6rem;
  }
}

.image-start {
  max-width: 992px;
  margin-left: auto;
}

.header-animate-content {
  clip-path: inset(0 0 100% 0);
}
.header-animate-content .header-content-text {
  transform: translateY(50px);
}

.header-animate-image {
  clip-path: inset(0 100% 0 0);
}
.header-animate-image img {
  transform: translateX(100px);
  opacity: 0;
}

.project-header {
  height: 100vh;
  height: 100svh;
  width: 100%;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .project-header {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .project-header {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .project-header {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .project-header {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .project-header {
    padding: 0 8rem;
  }
}
@media (min-width: 1200px) {
  .project-header {
    max-height: 1080px !important;
  }
}
@media (min-width: 1200px) {
  .project-header .content {
    margin-bottom: 15rem;
  }
}
.project-header .content .category-headline {
  margin-bottom: calc(0.5vw + 14.4px);
}
.project-header .content .header-title {
  font-size: clamp(1.7rem, 3.5vw, 5rem);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: calc(2vw + 25.6px);
}
.project-header .content .header-intro {
  width: 100%;
}
@media (min-width: 1200px) {
  .project-header .content .header-intro {
    width: 80%;
  }
}
.project-header .image {
  position: relative;
}
.project-header .image .gradient-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.project-header .image .header-image {
  position: relative;
  z-index: 1;
}

.project-facts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(150px, 100%), 1fr));
  gap: 1rem;
  margin-top: 2rem;
  white-space: nowrap;
  padding: 0 2rem;
  font-size: calc(0.125vw + 11.6px);
  font-weight: 600;
}
@media (min-width: 768px) {
  .project-facts {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .project-facts {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .project-facts {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .project-facts {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .project-facts {
    padding: 0 8rem;
  }
}
.project-facts .project-fact {
  flex: 1 1 150px;
}
.project-facts .project-fact:not(:last-child) {
  margin-right: 1rem;
}
.project-facts .project-fact li:first-child {
  color: #19827D;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.project-facts .project-fact li:not(:first-child),
.project-facts .project-fact li a {
  color: #66809E;
  font-weight: 300;
}
.project-facts .project-fact li a:hover {
  color: #19827D;
}

.project-intro {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  gap: 4rem;
  align-items: center;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .project-intro {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .project-intro {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .project-intro {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .project-intro {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .project-intro {
    padding: 0 8rem;
  }
}
.project-intro .content p {
  margin-bottom: 1rem;
}
.project-intro .image {
  padding: 0 4rem;
}
@media (min-width: 1200px) {
  .project-intro .image {
    padding: 0;
  }
}
.project-intro .image img {
  max-width: 250px;
  margin: 0 auto;
}

.project-main-image {
  max-width: 80%;
  margin-inline: auto;
}
@media (min-width: 1920px) {
  .project-main-image {
    max-width: 70%;
  }
}

.project-section-double {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  column-gap: 8rem;
  row-gap: 4rem;
  align-items: top;
}

@media (min-width: 1024px) {
  .project-sections .project-section-double:nth-child(even) .left {
    order: 2;
  }
  .project-sections .project-section-double:nth-child(even) .right {
    order: 1;
  }
}

@media (min-width: 1024px) {
  .project-overview .project-section-double:nth-child(even) .left {
    order: 2;
  }
  .project-overview .project-section-double:nth-child(even) .right {
    order: 1;
  }
}

.projects-overview {
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .projects-overview {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  .projects-overview {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  .projects-overview {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  .projects-overview {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  .projects-overview {
    padding: 0 8rem;
  }
}
.projects-overview .block-double {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8rem;
}
.projects-overview .block-double .left {
  flex: 1 1 700px;
}
.projects-overview .block-double .right {
  flex: 1 1 150px;
}
.projects-overview .block-double .right .block-disclaimer {
  margin-top: calc(0.625vw + 38px);
}

.full-page {
  max-width: 1263px;
  margin: 0 auto;
}

.lottie-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
  gap: 1rem;
}
.lottie-wrapper svg {
  transform: unset !important;
}

.image-age-gate {
  max-width: 750px;
}

.duong-skizze-06 {
  max-width: 1000px;
  margin-inline: auto;
}

.case-study-gdp {
  max-width: 670px;
}

.ticket {
  max-width: 750px;
  margin: 0 auto;
}

.case-study-smaforcity {
  max-width: 926px;
}

button {
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  outline: 0;
  border: solid 1px grey;
  border-radius: 6px;
  background: none transparent;
  color: inherit;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font: inherit;
  font-size: 0.9rem;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
}
button:hover {
  background-color: #19827D;
  color: #EFEFEF;
  border-color: #19827D;
}

.btn-border, .btn-color, .btn-plain, .btn {
  display: inline-block;
  border: none;
  border-radius: 2px;
  color: #EFEFEF;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8125rem;
  letter-spacing: 1.568px;
  font-weight: 500;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btn-plain {
  transition: color 0.35s;
  padding-left: 0;
}
.btn-plain:hover {
  color: #19827D;
}

.btn-color {
  transition: background-color 0.35s;
  background: #19827D;
}
.btn-color:hover {
  background: #0d423f;
}

.btn-border {
  transition: color 0.35s;
  transition: background-color 0.35s;
  border: solid 1px rgba(239, 239, 239, 0.25);
}
.btn-border:hover {
  color: #EFEFEF;
  border: solid 1px #19827D;
  background-color: #19827D;
}

.cgvn-input {
  margin-bottom: 1rem;
}
.cgvn-input input:not([type]),
.cgvn-input input[type=text]:not(.browser-default),
.cgvn-input input[type=password]:not(.browser-default),
.cgvn-input input[type=email]:not(.browser-default),
.cgvn-input input[type=url]:not(.browser-default),
.cgvn-input input[type=time]:not(.browser-default),
.cgvn-input input[type=date]:not(.browser-default),
.cgvn-input input[type=datetime]:not(.browser-default),
.cgvn-input input[type=datetime-local]:not(.browser-default),
.cgvn-input input[type=tel]:not(.browser-default),
.cgvn-input input[type=number]:not(.browser-default),
.cgvn-input input[type=search]:not(.browser-default),
.cgvn-input textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #3c4b5d;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 18px;
  font-family: inherit;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: background-color 0.3s;
  color: #EFEFEF;
  /* Für Webkit-basierte Browser wie Chrome und Safari */
  /* Für Firefox */
}
.cgvn-input input:not([type]):focus,
.cgvn-input input[type=text]:not(.browser-default):focus,
.cgvn-input input[type=password]:not(.browser-default):focus,
.cgvn-input input[type=email]:not(.browser-default):focus,
.cgvn-input input[type=url]:not(.browser-default):focus,
.cgvn-input input[type=time]:not(.browser-default):focus,
.cgvn-input input[type=date]:not(.browser-default):focus,
.cgvn-input input[type=datetime]:not(.browser-default):focus,
.cgvn-input input[type=datetime-local]:not(.browser-default):focus,
.cgvn-input input[type=tel]:not(.browser-default):focus,
.cgvn-input input[type=number]:not(.browser-default):focus,
.cgvn-input input[type=search]:not(.browser-default):focus,
.cgvn-input textarea:focus {
  background-color: #1e262f;
}
.cgvn-input input:not([type]):-webkit-autofill,
.cgvn-input input[type=text]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=password]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=email]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=url]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=time]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=date]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=datetime]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=datetime-local]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=tel]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=number]:not(.browser-default):-webkit-autofill,
.cgvn-input input[type=search]:not(.browser-default):-webkit-autofill,
.cgvn-input textarea:-webkit-autofill {
  /* Hintergrundfarbe ändern */
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 30px #14191F inset !important; /* Hier die gewünschte Hintergrundfarbe einsetzen */
  /* Textfarbe ändern */
  -webkit-text-fill-color: #EFEFEF; /* Hier die gewünschte Textfarbe einsetzen */
}
.cgvn-input input:not([type]):-moz-placeholder,
.cgvn-input input[type=text]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=password]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=email]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=url]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=time]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=date]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=datetime]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=datetime-local]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=tel]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=number]:not(.browser-default):-moz-placeholder,
.cgvn-input input[type=search]:not(.browser-default):-moz-placeholder,
.cgvn-input textarea:-moz-placeholder {
  /* Hintergrundfarbe ändern */
  background-color: transparent !important;
  box-shadow: 0 0 0 30px #14191F inset !important; /* Hier die gewünschte Hintergrundfarbe einsetzen */
  -webkit-text-fill-color: #EFEFEF; /* Hier die gewünschte Textfarbe einsetzen */
}
.cgvn-input textarea {
  min-height: 8rem;
}
.cgvn-input label {
  font-size: calc(0.125vw + 11.6px);
  min-width: 130px;
}
.cgvn-input .validation-message {
  color: rgb(255, 106, 106);
  font-size: 14px;
  height: 18px;
  line-height: 1;
  margin-top: 0.25rem;
}

.terms label {
  position: relative;
  top: unset;
  left: 0;
}
.terms a {
  text-decoration: underline;
  color: #EFEFEF;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  user-select: none;
}
.checkbox input[type=checkbox] + label::before {
  background-image: url("../images/static/cb-unchecked.svg");
}
.checkbox input[type=checkbox]:checked + label::before {
  background-image: url("../images/static/cb-checked.svg");
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox input[type=checkbox] + label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  width: 23px;
  height: 18px;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
}
.checkbox label {
  position: relative;
  left: 1.5rem;
  line-height: 1.2;
}
.checkbox label,
.checkbox a {
  font-size: 14px;
}
@media (min-width: 1200px) {
  .checkbox label,
  .checkbox a {
    font-size: 14px;
  }
}
.checkbox label a,
.checkbox a a {
  text-transform: initial;
  text-decoration: underline;
  color: #EFEFEF;
  transition: color 0.35s;
}
.checkbox label a:hover,
.checkbox a a:hover {
  color: #19827D;
}
.checkbox .privacy-invalid,
.checkbox .privacy-invalid a {
  color: rgb(255, 74, 74);
}

.form-cta {
  margin-top: 2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 2000;
  background-color: rgba(20, 25, 31, 0.75);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}
.modal .modal-inner {
  max-width: 90%;
  min-height: 50%;
  max-height: 80%;
  background: #EFEFEF;
  color: #14191F;
  padding: 2rem;
  border-radius: 10px;
  overflow-y: scroll;
  transform: translateY(30px);
  scrollbar-width: none;
}
@media (min-width: 768px) {
  .modal .modal-inner {
    max-width: 90%;
  }
}
@media (min-width: 1200px) {
  .modal .modal-inner {
    max-width: 50%;
  }
}
.modal .modal-inner::-webkit-scrollbar {
  width: 0 !important;
}
.modal .modal-inner .modal-header {
  font-size: calc(0.125vw + 17.6px);
}
.modal .modal-inner .modal-content h4 {
  margin-bottom: 2rem;
  font-size: calc(0.125vw + 15.6px);
}
.modal .modal-inner .modal-content a {
  color: #14191F;
}
.modal .modal-inner .modal-content ul {
  margin-bottom: 1rem;
}
.modal .modal-inner .modal-content .modal-terms {
  font-size: calc(0.125vw + 13.6px);
}
.modal .modal-inner .modal-content .modal-terms .modal-section {
  margin-bottom: 2rem;
}
.modal .modal-inner .modal-content .modal-terms .modal-section .hl {
  font-weight: bold;
}
.modal .modal-inner .modal-content .modal-terms .modal-section .sl {
  font-weight: 600;
  margin: 1rem 0 0.5rem 0;
}
.modal .modal-inner .modal-footer {
  margin-top: 3rem;
  text-align: center;
}
.modal .modal-inner .modal-footer .button-1 {
  display: none;
}

.modal-terms p {
  font-size: 1rem;
}

.toast {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  min-width: 80%;
  max-width: 360px;
  min-height: 100px;
  background-color: #19827D;
  color: white;
  border-radius: 4px;
  z-index: 300;
  padding: 2rem;
  z-index: 800;
  line-height: 1.5;
}
@media (min-width: 1024px) {
  .toast {
    min-width: 360px;
  }
}
.toast .toast-content {
  line-height: 1;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;
}
.projects .project {
  position: relative;
  transition: opacity 0.35s ease-in-out;
  padding: 2rem;
  border: 1px solid #222b35;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background: url("../images/static/gradient-card.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.projects .project .headline {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25.008px;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}
.projects .project .image {
  max-width: 100%;
  height: auto;
  transition: all 0.35s;
}
.projects .project .content .description {
  font-size: 1rem;
}
.projects .project .cta {
  position: absolute;
  left: 50%;
  bottom: 1rem;
  flex: 0 1 200px;
  text-align: center;
  opacity: 0;
  transition: all 0.35s ease-in-out;
  z-index: 1;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  transform: translate(-50%, 0);
  white-space: nowrap;
}
.projects:hover .project {
  cursor: pointer;
  opacity: 0.15;
}
.projects:hover .project:hover {
  opacity: 1;
  border: 1px solid #EFEFEF;
  z-index: 10;
}
.projects:hover .project:hover .image {
  transform: scale(1.35);
  transform-origin: 50% 30%;
}
@media (min-width: 768px) {
  .projects:hover .project:hover .image {
    transform: scale(1.3);
  }
}
.projects:hover .project:hover .cta {
  opacity: 1;
  transform: translate(-50%, -20px);
}

.before-after {
  position: relative;
  margin: 0 auto;
  margin-top: calc(3.125vw + 40px);
}
@media (min-width: 1200px) {
  .before-after {
    width: 70%;
  }
}
@media (min-width: 1920px) {
  .before-after {
    width: 80%;
  }
}
.before-after img {
  max-width: 90vw;
  border-radius: 0.8rem;
  display: block;
}
@media (min-width: 1200px) {
  .before-after img {
    max-width: 80vw;
  }
}
@media (min-width: 1920px) {
  .before-after img {
    max-width: 1400px;
  }
}
.before-after .before,
.before-after .after {
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}
.before-after .before {
  position: relative;
  z-index: 1;
}
.before-after .after {
  position: absolute;
  z-index: 0;
}
.before-after .disclaimer {
  margin-top: 1rem;
}

.compare-images {
  width: 90%;
  margin: 0.5rem auto;
  border: solid 1px #14191F;
  border-radius: 10px;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .compare-images {
    margin: 3rem auto;
    width: 70%;
    border-radius: 20px;
  }
}
.compare-images .compare-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  border: solid 1px #14191F;
}
.compare-images .compare-slider .drag-before,
.compare-images .compare-slider .drag-after {
  display: flex;
  justify-content: center;
  background-color: #14191F;
}
.compare-images .compare-slider .drag-before {
  position: relative;
  z-index: 0;
}
.compare-images .compare-slider .drag-after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}
.compare-images .dragger {
  position: absolute;
  top: 0;
  left: -3px;
  width: 0.25rem;
  background-color: #19827d;
  align-items: center;
  z-index: 10;
}
.compare-images .dragger:after {
  content: url("../images/static/ic-slide.png");
  position: absolute;
  top: calc(50% - 20px);
  left: -1.12rem;
  width: 30px;
  height: 30px;
  z-index: 11;
  border-radius: 50%;
}
.compare-images .dragger img {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: -15px;
  transform: translate(-50% -50%);
  z-index: 2;
}

.case-study-logo {
  max-height: 400px;
  width: auto;
}

.color-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100px, 100%), 1fr));
  gap: 2rem;
}
.color-cards .color-card {
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #000;
  padding: 1rem;
  border-radius: 2px;
  transition: transform 0.35s;
  text-align: center;
}
.color-cards .color-card:hover {
  transform: scale(1.3);
}
.color-cards .color-card .color-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: calc(0vw + 12px);
}
.color-cards .color-card-inverted {
  color: #14191F;
}

.font-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  gap: 4rem;
}
.font-cards img {
  max-width: 350px;
}
.font-cards .fonts-pixel {
  max-width: 540px;
}

#scroll-top {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 800;
  display: flex;
  visibility: hidden;
  transform: translateY(30px);
}
@media (min-width: 1921px) {
  #scroll-top {
    left: calc((100vw - 1920px) / 1.95);
  }
}
@media (hover: hover) {
  #scroll-top:hover {
    cursor: pointer;
  }
  #scroll-top:hover .chevron {
    fill: #19827D;
  }
}
#scroll-top .chevron {
  transition: fill 0.35s;
}

footer {
  position: relative;
  padding-top: 3rem;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  footer {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  footer {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  footer {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  footer {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  footer {
    padding: 0 8rem;
  }
}
footer .footer-gradient {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 900px;
  z-index: 0;
}
footer .footer-border {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #2a3541;
  margin-bottom: 3rem;
  z-index: 1;
}
footer .footer-sections {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  column-gap: 1rem;
  row-gap: 3rem;
  justify-items: center;
}
@media (min-width: 1024px) {
  footer .footer-sections {
    grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
    gap: 1rem;
    justify-items: stretch;
  }
}
footer .footer-sections .section-left {
  text-align: center;
}
@media (min-width: 1024px) {
  footer .footer-sections .section-left {
    text-align: left;
  }
}
footer .footer-sections .section-left .company-name {
  font-size: calc(0.25vw + 17.2px);
  font-weight: 700;
}
footer .footer-sections .section-left .adress a {
  color: #EFEFEF;
}
footer .footer-sections .section-left .footer-links {
  margin-top: 2rem;
}
footer .footer-sections .section-left .footer-links .footer-link {
  color: #66809E;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
footer .footer-sections .section-middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
footer .footer-sections .section-middle .made-with {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
footer .footer-sections .section-middle .made-with svg {
  width: calc(0.25vw + 11.2px);
}
footer .footer-sections .section-middle .made-with span {
  text-transform: uppercase;
  font-weight: 500;
  color: #66809E;
  font-size: calc(0.125vw + 11.6px);
  line-height: 1.1;
}
footer .footer-sections .section-middle .links {
  color: #66809E;
}
footer .footer-sections .section-middle .links a {
  color: #66809E;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
footer .footer-sections .section-right {
  text-align: center;
}
@media (min-width: 1024px) {
  footer .footer-sections .section-right {
    text-align: right;
  }
}
footer .footer-sections .section-right .logo-footer svg {
  max-width: 125px;
  fill: #EFEFEF;
}
footer .footer-sections .section-right .claim-footer {
  font-size: calc(0.9375vw + 17px);
  font-weight: 700;
  line-height: 1;
}
footer .footer-copyright {
  width: 100%;
  text-align: center;
  padding: 3rem 0;
  color: #516780;
  font-size: 12px;
  font-weight: 500;
}

form {
  max-width: 800px;
  margin-inline: auto;
}

.leistungen {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  gap: 4rem;
}
.leistungen .leistung-titel {
  font-size: calc(0.125vw + 17.6px);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: calc(0.5vw + 6.4px);
}

.cgvn-privacy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  visibility: hidden;
}
.cgvn-privacy .cgvnp-modal {
  position: absolute;
  width: 90%;
  background: #19827D;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4);
  font-weight: normal;
}
@media (min-width: 768px) {
  .cgvn-privacy .cgvnp-modal {
    width: 50vw;
  }
}
@media (min-width: 1024px) {
  .cgvn-privacy .cgvnp-modal {
    width: 40vw;
  }
}
@media (min-width: 1200px) {
  .cgvn-privacy .cgvnp-modal {
    width: 35vw;
  }
}
@media (min-width: 1400px) {
  .cgvn-privacy .cgvnp-modal {
    width: 450px;
  }
}
.cgvn-privacy .cgvnp-modal .cgvnp-image {
  position: absolute;
  top: -65px;
  right: -20px;
  max-width: 188px;
  min-height: 188px;
  z-index: 0;
}
@media (min-width: 390px) {
  .cgvn-privacy .cgvnp-modal .cgvnp-image {
    top: -65px;
  }
}
.cgvn-privacy .cgvnp-modal .cgvnp-image img {
  max-width: 188px;
  max-height: 188px;
}
.cgvn-privacy .cgvnp-modal .cgvnp-head {
  width: 70%;
  padding: 1rem;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  color: #ffffff;
}
@media (min-width: 768px) {
  .cgvn-privacy .cgvnp-modal .cgvnp-head {
    width: 70%;
  }
}
.cgvn-privacy .cgvnp-modal .cgvnp-content {
  width: 100%;
  padding: 1rem;
  font-size: 12px;
  color: #ffffff;
  z-index: 1;
}
.cgvn-privacy .cgvnp-modal .cgvnp-content .cgvnp-text {
  width: 100%;
  letter-spacing: 1px;
}
.cgvn-privacy .cgvnp-modal .cgvnp-content .cgvnp-text:first-child {
  width: 100%;
  margin-bottom: 1rem;
}
.cgvn-privacy .cgvnp-modal .cgvnp-content a {
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.25s;
}
.cgvn-privacy .cgvnp-modal .cgvnp-content a:hover {
  color: #0d423f;
}
.cgvn-privacy .cgvnp-modal .cgvnp-footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-top: solid 1px #2bd6ce;
  z-index: 1;
}
.cgvn-privacy .cgvn-btn {
  border: none;
  border-radius: 2px;
  width: 33.333%;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  font-family: inherit;
  text-transform: uppercase;
  vertical-align: middle;
  background: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #ffffff;
  transition: color 0.25s;
}
@media (min-width: 390px) {
  .cgvn-privacy .cgvn-btn {
    letter-spacing: 2px;
  }
}
.cgvn-privacy .cgvn-btn:nth-child(2) {
  border-left: solid 1px #2bd6ce;
  border-right: solid 1px #2bd6ce;
}
.cgvn-privacy .cgvn-btn:hover {
  color: #0d423f;
}
.cgvn-privacy .cgvn-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cgvn-privacy .cgvn-bottom-left {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .cgvn-privacy .cgvn-bottom-left {
    transform: unset;
    left: 1rem;
  }
}
.cgvn-privacy .cgvn-bottom-right {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .cgvn-privacy .cgvn-bottom-right {
    left: unset;
    transform: unset;
    right: 1rem;
  }
}

.cgvn-privacy-settings {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  visibility: hidden;
  z-index: 3000;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal {
  width: 90%;
  height: 95%;
  background: #19827D;
  color: #ffffff;
  border-radius: 4px;
  padding: 1rem;
  font-size: 14px;
  z-index: 3002;
  overflow-y: auto;
  visibility: hidden;
}
@media (min-width: 390px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    height: auto;
  }
}
@media (min-width: 768px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    width: 60%;
  }
}
@media (min-width: 1024px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    width: 40%;
  }
}
@media (min-width: 1400px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    width: 35%;
  }
}
@media (min-width: 1920px) {
  .cgvn-privacy-settings .cgvn-privacy-settings-modal {
    width: 30%;
    max-width: 580px;
  }
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvns-head {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvns-types {
  margin-bottom: 2rem;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvns-types .cookie-type {
  margin-bottom: 2rem;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvns-types .cookie-type .type-head {
  border-bottom: solid 1px #2bd6ce;
  padding-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvns-footer {
  text-align: center;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvn-switch input[type=checkbox] {
  width: 0;
  height: 0;
  visibility: hidden;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvn-switch label {
  width: 45px;
  height: 22px;
  display: block;
  background-color: #115754;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvn-switch label::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #19827D;
  position: absolute;
  border-radius: 70px;
  top: 3px;
  left: 3px;
  transition: 0.35s ease-in-out;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvn-switch input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
  background: #80e7e2;
}
.cgvn-privacy-settings .cgvn-privacy-settings-modal .cgvn-switch input:checked + label {
  background-color: #1d9792;
}
.cgvn-btn {
  display: inline-block;
  border: none;
  border-radius: 2px;
  width: 33.333%;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  margin: 0 auto;
  text-transform: uppercase;
  vertical-align: middle;
  background: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 0;
  color: #ffffff;
  transition: color 0.25s;
}
@media (min-width: 390px) {
  .cgvn-btn {
    letter-spacing: 2px;
  }
}
.cgvn-btn:nth-child(2) {
  border-left: solid 1px #2bd6ce;
  border-right: solid 1px #2bd6ce;
}
.cgvn-btn:hover {
  color: #0d423f;
}

html.has-scroll-smooth {
  overflow: hidden;
}
html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}
[data-scroll-direction=horizontal] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction=horizontal] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction=horizontal] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction=horizontal] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: grabbing;
}
[data-scroll-direction=horizontal] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

.smooth-scroll {
  position: relative;
  width: 100%;
}

.c-scrollbar .c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #28323e;
  opacity: 1;
  width: 3px;
  border-radius: 10px;
  margin: 2px;
  cursor: grab;
}
